.bet-btn-st {
  border: 0;
  width: 200px;
  height: 40px;
  color: #ffffff !important;
  background-color: #40ae49;
  border-radius: 10px;
  text-align: center;
  padding-top: 7px;
  font-weight: bold;
}

.betdeex-hero-bgd {
  background-image: url("../../assets/images/bgdtwo.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
}

li a {
  color: #000 !important;
}

.wrapper-container {
  width: 72%;
  margin: 0 auto;
  margin-top: 2%;
}

.ftr-txt {
  color: #40ae49;
  font-weight: bold;
}

.bet-btn-st.mg-left {
  margin-left: 20px;
}

.sect-container {
  background-color: #ffffff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.sect-head {
  color: #40ae49;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.evnt-img {
  width: 25%;
  height: 285px;
  object-fit: fill;
}

.sect-head {
  /* margin-top: 5%; */
}

.pop-ev-box {
  border: 1px solid #000;
  height: 300px;
  width: 200px;
}

.row.inner-sect {
  width: 60%;
  justify-content: space-between;
  margin-left: 20%;
}

.wrapper-container.hero-mg {
  padding-top: 10%;
}

.header.online-payment-header.section.color-1.alpha-9 {
  background-image: url("../../assets/images/banner-bg.jpg");
  /* height: 500px;  */
  z-index: 9;
  background-position-y: center;
}

.decent-txt {
  font-weight: 800;
  font-style: italic;
}

.decnt-txt2 {
  font-weight: 800;
  font-style: italic;
  margin-bottom: 50px;
  font-size: 20px;
  margin-left: 32%;
}

.img-responsive.bet-logo {
  /* width: 100%;
        height: 175px; 
        margin-left: 95px;*/
  margin-top: 82px;
}

h2 {
  font-weight: 800;
  color: #41494f;
}

.lp.spe-bot-red-3 {
  z-index: 9;
}
[class~="hom-trend-img"] img:hover {
  transform: scale(1.1);
  -webkit-transition: all 0.5s ease;
}

[class~="h_r"] img,
[class~="hom-trend-img"] img {
  width: 100%;
}

.section.bg-6.b-b.edge {
  z-index: 100;
}

.hom-top-trends.row.mrg {
  margin-bottom: 40px;
}

[class~="hom-trend-img"],
.hom-search,
.hom-search,
[class~="hovereffect"] h2 {
  overflow: hidden;
}

[class~="hovereffect"] img,
[class~="hom-trend-img"],
.hom-search,
.hom-search {
  position: relative;
}

.txt-effect {
  text-align: center;
  font-size: 16px;
  color: #41494f;
  text-transform: uppercase;
  margin-top: 20px;
}

.txt-align {
  text-align: center;
}

.txt-justify {
  text-align: justify;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: -14px;
  bottom: 0;
  z-index: 111111111;
  display: -ms-flexbox;
  display: inline;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 4%;
  color: #000;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  background: #000;
  height: 27px;
  padding: 3px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.card.mb-3.bx-effect {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.home-flex {
  display: flex;
  justify-content: center;
}

.carousel-control-next .fa,
.carousel-control-prev .fa {
  color: #fff;
}
