.main-ev-desc {
  display: flex;
  justify-content: space-between;
}

.bet-value {
  border: 1px solid #40ae49;
  padding: 6px 7px;
  height: 35px;
  position: relative;
  top: 6px;
}

.sd-txt {
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}

.sidebar-container {
  padding: 10px;
}
.main-ev-desc.ev-border {
  border-bottom: 0.5px solid #a7a7a7;
  box-shadow: #a7a7a7;
  margin-bottom: 10px;
  margin-top: 0px;
  padding-top: 0;
}
.catg-side {
  padding-top: 10px;
  padding-bottom: 17px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #41ae4a;
}
.sidebarmenu {
}
