.searchBox {
  /* display: flex;
  justify-content: flex-end; */
}

input {
  outline: none;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
  box-shadow: 0 1px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type="search"] {
  background: #ededed
    url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat
    9px center;
  border: 2px solid #ccc;
  padding: 9px 10px 9px 32px;
  width: 265px;
  box-shadow: 0 1px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
input[type="search"]:focus {
  width: 360px;
  background-color: #fff;
  border-color: #66cc75;

  -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
}

input:-moz-placeholder {
  color: #999;
}
input::-webkit-input-placeholder {
  color: #999;
}

.bet-btn-st.ev-btn {
  border: 0;
  width: 300px;
  height: 40px;
  color: #ffffff;
  background-color: #40ae49;
  border-radius: 10px;
  padding-bottom: 10px;
}

.event-head {
  text-align: center;
  color: #40ae49;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 30px;
}

.event-innr {
  margin: 20px;
  padding: 50px 30px 50px 30px;
}

.left-ev-head {
  border-right: 1px solid #a7a7a76b;
}
.left-ev-head .left-block {
  display: inline-block;
  width: 100%;
}
.row.pd-box {
  margin: 10px;
  border-bottom: 1px solid #0000002e;
  padding: 0 0 20px 0;
}

.catg-ev-one {
  display: flex;
}

.main-ev-desc {
  display: flex;
  justify-content: space-between;
}

.ev-title {
  color: #40ae49;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 36px;
}

.wd-event {
  /* width: 80%; */
}

.deployer-style {
  border: 1px solid #40ae49;
  padding: 10px;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  border-radius: 20px;
}

.bet-border-bx {
  border: 1px solid #a7a7a7;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  margin-right: 15px;
  text-align: center;
}

a.bet-view {
  color: #fff !important;
  background-color: #40ae49;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  line-height: 43px;
  font-weight: 400;
  padding: 0 10px;
  min-width: 150px;
}
a.bet-view:hover {
  color: #fff !important;
  background-color: #333333;
}

.view-rght {
  display: flex;
  justify-content: space-between;
  padding: 0px;
}

.ev-txt {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  margin: 15px 0 0 0;
}

.custom-select.select-style {
  width: 300px;
  height: 50px;
}

.select-selected.select-arrow-active:after {
  border-color: transparent transparent #000 transparent;
  top: 7px;
}

.com-tab .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #000000;
  font-size: 21px;
  font-weight: 600;
  padding: 5px 18px;
  letter-spacing: 0;
  width: 120px;
  border: 1px solid #c1bfbf;
  border-radius: 0;
  text-align: center;
  border-bottom: 3px solid #000;
}

.com-tab #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #861502;
  border-bottom: 3px solid #1d0703 !important;
  font-size: 21px;
  font-weight: bold;
}

.tab-content > .active {
  display: block;
}

[class~="myTable"] {
  background: #fff;
}

[class~="myTable"] th {
  padding: 10px;
}

.myTable th {
  color: #000;
  font-size: 26px;
}

.btdex-logo-modal {
  width: 200px;
  height: 65px;
  margin-bottom: 25px;
}

.close-style {
  font-size: 45px;
  color: #861502;
}

.modal-header {
  padding: 5px;
}

.select-align {
  display: flex;
  justify-content: center;
}

.md-lb-txt {
  font-weight: bold;
}
.btn.btn-warning {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 10px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: red;
}

::-webkit-form-control.form-control-lg-placeholder {
  font-size: 22px;
}

.catg-ev-one p,
.catg-ev-two p {
  font-size: 13px;
  font-weight: 600;
}

.left-block .bd-lines {
  margin: 15px 0 0 0;
}

.innerpage-title {
  /* display: flex;
  justify-content: space-between; */
}

.fa.fa-thumbs-up {
  font-size: 36px;
  color: #a7a7a7;
  margin: 10px;
}

.fa.fa-thumbs-down {
  font-size: 36px;
  color: #a7a7a7;
  margin: 10px;
}

.bd-lines {
  margin-left: 15px;
}

.btn.btn-warning {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 10px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: red;
}

::-webkit-form-control.form-control-lg-placeholder {
  font-size: 22px;
}
