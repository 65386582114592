.footer-container {
  width: 77%;
  margin: 0 auto;
  margin-top: 5%;
  padding: 35px;
}

.footer-bgd-color {
  background-color: #ffffff;
}

.social-img {
  width: 30px;
  height: 30px;
  margin: 7px;
  margin-top: 20px;
}

.social-sm-flex {
  display: flex;
}

ul li {
  list-style: none;
}

.btdex-logo {
  height: 51px;
  margin-bottom: 0px;
}

.social-container {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #a7a7a7;
}

/* .bd-lines{
    color: #343a407a;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 18px;
} */

@media screen and (max-width: 468px) {
  .social-container {
    display: block;
    justify-content: space-between;
    border-top: 1px solid #a7a7a7;
  }
}

.social-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-menu li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 6px;
}
.social-menu li a i {
  width: 31px;
  height: 30px;
  background-color: #fff0;
  text-align: center;
  line-height: 30px;
  border-radius: 3px;
  color: #65bc46;
  font-size: 14px;
  box-shadow: 0 0 0 0 transparent;
  transition: 0.3s;
  border: 1px solid #539e38;
}

.social-menu li a:hover i {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
