react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  box-sizing: border-box;
  background: #fff;
}

.form-control {
  height: 60px;
}

.wrapper-container.create-container {
  width: 40%;
}

select.form-control:not([size]):not([multiple]) {
  height: 60px;
}

label {
  color: #000;
  font-weight: 400;
  font-size: 22px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-date-picker.react-date-picker--closed.react-date-picker--enabled {
  width: 40%;
  height: 50px;
}

.btn.btn-success {
  font-size: 22px;
}

.custom-note {
  font-size: 18px;
  color: #981802;
}
