.nav-flex {
  display: flex;
  width: 60%;
  justify-content: center;
}

.nav-two-container {
  background-color: #ffffff;
  height: 19px;
  border-bottom: 1px solid #d8d7d7;
}

.nav-flex li {
  margin-left: 20px;
  margin-top: 15px;
}

.nav-top-flex {
  display: flex;
  justify-content: center;
}
.bd-lines {
  color: #000;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 13px;
}
.nav-link.dropdown-toggle {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
  letter-spacing: 1.45px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  white-space: normal;
  text-align: left;
  border: 1px solid #66cc75;
}

.nav-list-txt {
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 30px;
  padding-right: 25px;
}

.nav-list-txt:hover {
  color: #66cc75;
}

a {
  color: #000 !important;
  text-decoration: none;
}

.grn-bd {
  margin-top: 0;
  border-left: 2px solid #66cc75;
  margin: 0 8px;
}

.navbar.navbar-light.justify-content-around {
  background: linear-gradient(to right, #000 0%, #981802 100%);
  z-index: 999;
}

.nav-link.dropdown-toggle.txt-color {
  color: #66cc75 !important;
  font-size: 22px;
  font-weight: bold;
}

.nav-list-txt {
  cursor: pointer;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  font-weight: 600 !important;
  font-size: 13px !important;
  color: #505050 !important;
  margin-top: 13px;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #28a745 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
  border-bottom: 2px solid #dedada;
}
