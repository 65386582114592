.tab-com .nav-link.active,
.tab-com .show > .nav-link {
  background-color: #40ae49;
  color: #fff !important;
}
.thumbnail {
  padding: 0px;
}
.panel {
  position: relative;
}
.comments {
  max-height: 460px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.panel > .panel-heading:after,
.panel > .panel-heading:before {
  position: absolute;
  top: 11px;
  left: -16px;
  right: 100%;
  width: 0;
  height: 0;
  display: block;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
  pointer-events: none;
}
.panel > .panel-heading:after {
  border-width: 7px;
  border-right-color: #f7f7f7;
  margin-top: 1px;
  margin-left: 2px;
}
.panel > .panel-heading:before {
  border-right-color: #ddd;
  border-width: 8px;
}
